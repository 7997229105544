import {
  App,
  formatDisplayName,
  getLogoForApp,
  config,
} from '@encompaas/common';
import { useMsalLogout } from '@encompaas/common/auth';
import {
  AppBarWithActions,
  Box,
  ZenDeskSupport,
  AboutDialog,
} from '@encompaas/common/components';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useUser, useGetUserServiceAccess } from '@encompaas/common/hooks';
import { Applications } from '../components/Applications';
import { AppPage } from './AppPage';
import { useState } from 'react';

// Needed for Zendesk to work
declare global {
  interface Window {
    zE: any;
  }
}

export const HomePage = () => {
  const user = useUser();
  const onLogout = useMsalLogout();

  // Create a list of applications that the user has access to for rendering
  const applications =
    user && user['ConfiguredApps']
      ? user['ConfiguredApps']
          .filter((app: App) => app.IsAuthorised) // Only show apps we are authorised to use
          .map((app: App) => {
            return {
              name: formatDisplayName(app.DisplayName),
              Logo: getLogoForApp(formatDisplayName(app.DisplayName)),
              url: app.Homepage,
            };
          })
      : [];

  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);
  const userServiceAccess = useGetUserServiceAccess();

  const tenant = userServiceAccess.data?.AccessibleDatasets[0]?.TenantName;
  const version = process.env.REACT_APP_VERSION;
  const build = config.REACT_APP_ENC_BUILD;
  const commit = config.REACT_APP_ENC_COMMIT;
  const handleHelpOption = (option: string) => {
    switch (option) {
      case 'Help':
        //Clicking 'Help' action opens the EncompaaS Help webpage in another Browser tab
        window.open('https://encompaas.zendesk.com/hc/en-us', '_blank');
        break;

      case 'Community':
        //Clicking 'Community' action opens the EncompaaS Community webpage in another Browser tab
        window.open('https://community.en.support.encompaas.cloud/', '_blank');
        break;

      case 'Contact us':
        //open zenDesk support.
        window.zE('webWidget', 'show');
        window.zE('webWidget', 'open');
        break;

      case 'About':
        //open about dialog
        setAboutDialogOpen(true);
        break;

      default:
        //Handle additional action logic, if required.
        //...
        break;
    }
  };

  return (
    <AppPage data-testid={TEST_CONSTANTS.HOME_PAGE}>
      <Box className={'apps-page-container'} background='none'>
        <AppBarWithActions
          user={user}
          color='secondary'
          data-testid={TEST_CONSTANTS.APP_BAR}
          onLogout={onLogout}
          onHelpOption={handleHelpOption}
          helpOptions={[
            'Help',
            'Community',
            'Contact us',
            'About',
            `${config.TITLE} v${config.VERSION}`,
          ]}
          background='none'
          height={5}
        />

        <Applications
          isLoading={user?.isUserApiFetching ?? true}
          applications={applications}
        />
      </Box>

      <AboutDialog
        open={aboutDialogOpen}
        onClose={() => setAboutDialogOpen(false)}
        tenant={tenant}
        version={version}
        build={build}
        commit={commit}
      />

      <ZenDeskSupport />
    </AppPage>
  );
};
